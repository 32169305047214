import moment from 'moment'
import * as React from 'react'
import { Card, CardBody } from 'reactstrap'

import { BadgeLabel, IndicatorProgressBar } from 'components/common'
import type { ColorType } from 'components/common/types'

import { calculateProductivityMetrics, getPlanTime } from './utils'

import styles from './ScheduleTypeProgressCard.module.scss'

type ScheduleTypeProgress = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  targetCount: number | null // 目標値
  todayPlanCount: number | null // 1日分の合計計画値
  planCount: number | null // 現在時刻までの計画値
  planCountQuarterHourAgo: number | null // 15分単位で切り下げた計画値
  planningHour: number | null // // 15分単位で切り下げた計画時間の合計
  todayPlanningHour: number | null // 当日の計画時間の合計
  recordCount: number | null // 実績値
  recordCountQuarterHourAgo: number | null // 15分単位で切り下げた実績値
  lastImportedAt: string
  unit: string
}

type Props = {
  scheduleTypeProgress: ScheduleTypeProgress
}

const displayValue = (value: number) => {
  const sign = value > 0 ? '+' : ''
  return sign + value.toLocaleString()
}

const ScheduleTypeProgressCard: React.FC<Props> = ({ scheduleTypeProgress }) => {
  const {
    targetCount,
    todayPlanCount,
    planCount,
    recordCount,
    planningHour,
    unit,
    scheduleTypeName,
    scheduleTypeColor,
    lastImportedAt,
  } = scheduleTypeProgress

  const achievementRate = todayPlanCount && recordCount ? Math.floor((100 * recordCount) / todayPlanCount) : 0
  const diffPlanCount = planCount && recordCount ? Math.floor(recordCount - planCount) : 0
  const planRate = todayPlanCount && planCount ? Math.floor((100 * planCount) / todayPlanCount) : 0
  // (recordCount - planCount) / todayPlanCount の値が-0.1以下か0.1以上は警告表示する
  const errorRate = todayPlanCount && planCount && recordCount ? (recordCount - planCount) / todayPlanCount : 0
  const warning = Math.abs(errorRate) >= 0.1
  const truncatedTodayPlanCount = todayPlanCount ? Math.floor(todayPlanCount) : 0
  const formattedLastImportedAt = moment.utc(lastImportedAt).format('YYYY/MM/DD HH:mm:ss')

  const { currentProductivity, productivityDifferenceRatio, planGoalForecasting, forecastShortfallHour } =
    calculateProductivityMetrics(scheduleTypeProgress)

  const customValueStyle = React.useCallback((value: number) => (value < 0 ? styles.valueWarning : ''), [])

  return (
    <>
      <Card className="mb-3 me-3">
        <CardBody className="d-flex justify-content-between align-items-center px-3 py-2">
          <BadgeLabel label={scheduleTypeName} color={scheduleTypeColor} />
          <div className="d-flex align-items-end">
            <span className=" my-auto font-x-small text-muted pe-2">実績</span>
            <span className="font-x-large fw-bold pe-2">{recordCount?.toLocaleString() || 0}</span>
            <span className="font-x-small text-muted mb-1">{unit}</span>
          </div>
        </CardBody>
        <Card className="mx-3">
          <CardBody className="px-3 pt-2 pb-0">
            <div className="d-flex justify-content-between font-x-small text-muted">
              <span>計画達成率</span>
              <span>{getPlanTime().toLocaleString()}時点の計画との差</span>
            </div>
            <div className="d-flex justify-content-between font-small">
              <span>{achievementRate.toLocaleString()}%</span>
              <span className={customValueStyle(diffPlanCount)}>{displayValue(diffPlanCount)}</span>
            </div>
            <div className="mt-2">
              <IndicatorProgressBar
                achievementRate={achievementRate}
                planRate={planRate}
                warning={warning}
                unit={unit}
                planCount={planCount}
              />
            </div>
          </CardBody>
          <CardBody className="d-flex justify-content-between border-top px-3 py-2">
            <div className="font-x-small text-muted">計画</div>
            <div>
              <span>{truncatedTodayPlanCount.toLocaleString()}</span>
              <span className="ms-1">{unit}</span>
            </div>
          </CardBody>
          <CardBody className="d-flex justify-content-between border-top px-3 py-2">
            <div className="font-x-small text-muted">目標</div>
            <div>
              <span>{targetCount ? targetCount.toLocaleString() : 0}</span>
              <span className="ms-1">{unit}</span>
            </div>
          </CardBody>
        </Card>

        <Card className="mt-2 mx-3">
          {planningHour ? (
            <CardBody className="d-flex justify-content-between align-items-center px-3 py-2">
              <div className="font-x-small text-muted">{getPlanTime(false)}までの生産性</div>
              <div>
                <span className="fw-bold font-x-large me-1">{currentProductivity.toLocaleString()}</span>
                <span className="font-x-small text-muted align-top">{unit}/時間</span>
              </div>
              <div className={customValueStyle(productivityDifferenceRatio)}>{`${displayValue(
                productivityDifferenceRatio
              )}%`}</div>
            </CardBody>
          ) : (
            <CardBody className="d-flex justify-content-between align-items-center px-3 py-2">
              <div className="font-x-small text-muted">{getPlanTime(false)}までの作業予定はありません</div>
            </CardBody>
          )}

          <CardBody className="d-flex justify-content-between align-items-center border-top px-3 py-2">
            <div className="font-x-small text-muted">過不足予測 ({unit})</div>
            <div>
              <span className={customValueStyle(planGoalForecasting)}>{displayValue(planGoalForecasting)}</span>
              <span className="ms-1">{unit}</span>
            </div>
          </CardBody>
          <CardBody className="d-flex justify-content-between align-items-center border-top px-3 py-2">
            <div className="font-x-small text-muted">過不足予測 (人時)</div>
            <div>
              <span className={customValueStyle(forecastShortfallHour)}>
                {forecastShortfallHour > 0 ? '+' : ''}
                {forecastShortfallHour.toFixed(1)}
              </span>
              <span className="ms-1">人時</span>
            </div>
          </CardBody>
        </Card>

        <CardBody className="d-flex text-muted justify-content-end align-items-center px-3 py-2">
          <i className="icf-updated pe-1" />
          <small>{formattedLastImportedAt}</small>
        </CardBody>
      </Card>
    </>
  )
}

export default ScheduleTypeProgressCard
